import styled from 'styled-components';
import { CardGiftcard } from '@styled-icons/material-rounded/CardGiftcard';
import { QrCodeScanner } from '@styled-icons/material-rounded/QrCodeScanner';
import { Email } from '@styled-icons/material-rounded/Email';
import { PhoneIphone } from '@styled-icons/material-twotone/PhoneIphone';
import { Number as Number2 } from '@styled-icons/octicons/Number';
import { Ticket } from '@styled-icons/remix-fill/Ticket';

import { useTranslations } from 'next-intl';
import { Tiles } from '@rebass/layout';

import Section from '@/components/section';

const StepContainer = styled.div`
  text-align: center;
  padding: 0 1em;
`;

const Title = styled.div`
  margin: 1em 0 0.5em;
  font-weight: 600;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 0.9em;
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  padding: 0 1em;
  text-align: center;
`;

const HeaderTitle = styled.h1`
  color: ${({ theme }) => theme.colors.typography.primary};
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1.5em;
`;

const Step = ({ IconComponent, iconColor, title, subtitle }) => (
  <StepContainer>
    <IconComponent size={50} color={iconColor} />
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </StepContainer>
)

// TODO: Also handle 'data', 'digitaltv', 'bundle', 'longdistance'

const HowItWorks = ({ background = 'transparent', type = 'giftcard' }) => {
  const t = useTranslations('howItWorks');

  return (
    <>
      {['topup', 'pin', 'giftcard'].includes(type) &&
        <Section background={background}>
          <InnerContainer>
            <HeaderTitle>{t('title')}</HeaderTitle>
            {type === 'giftcard' &&
              <Tiles columns={[1, 3]}>
                <Step IconComponent={CardGiftcard} iconColor="#0070f3" title={t('step1Title')} subtitle={t('step1Description')} />
                <Step IconComponent={QrCodeScanner} iconColor="#E4517F" title={t('step2Title')} subtitle={t('step2Description')} />
                <Step IconComponent={Email} iconColor="#66BB6A" title={t('step3Title')} subtitle={t('step3Description')} />
              </Tiles>
            }
            {type === 'pin' &&
              <Tiles columns={[1, 3]}>
                <Step IconComponent={Ticket} iconColor="#0070f3" title={t('step1Title')} subtitle={t('step1Description')} />
                <Step IconComponent={QrCodeScanner} iconColor="#E4517F" title={t('step2Title')} subtitle={t('step2Description')} />
                <Step IconComponent={Email} iconColor="#66BB6A" title={t('step3Title')} subtitle={t('step3Description')} />
              </Tiles>
            }
            {type === 'topup' &&
              <Tiles columns={[1, 3]}>
                <Step IconComponent={Number2} iconColor="#0070f3" title="1. Enter your phone number & amount" subtitle={t('step1Description')} />
                <Step IconComponent={QrCodeScanner} iconColor="#E4517F" title={t('step2Title')} subtitle={t('step2Description')} />
                <Step IconComponent={PhoneIphone} iconColor="#66BB6A" title={t('step3Title')} subtitle={t('step3Description')} />
              </Tiles>
            }
            {/* {type === 'digitaltv' && <Grid gutter="lg" minItemWidth={200}>
              <Step IconComponent={Number} iconColor="#0070f3" title="1. Enter your account number & amount" subtitle={t('step1Description')} />
              <Step IconComponent={QrCodeScanner} iconColor="#E4517F" title="2. Pay with crypto" subtitle={t('step2Description')} />
              <Step IconComponent={Tv} iconColor="#66BB6A" title={t('step3Title')} subtitle={t('step3Description')} />
            </Grid>} */}
          </InnerContainer>
        </Section>
      }
    </>
  );
};

export default HowItWorks;
